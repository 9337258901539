var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.xsOnly)?_c('v-data-iterator',{attrs:{"items":_vm.data,"item-key":"name","items-per-page":10,"search":_vm.search,"loading":_vm.loadingData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_vm._l((items),function(client,i){return [_c('mobile-clients',{key:'mobilec-'+i,attrs:{"cliente":client},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 pa-2",attrs:{"depressed":"","color":"secondary","outlined":"","min-width":"23px","height":"31px","to":{name: 'Cliente', params: { cod: client.co_cli.trim() }}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-text-box-check-outline")])],1)]},proxy:true}],null,true)})]})]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',_vm._b({attrs:{"type":"list-item-two-line, list-item, list-item-two-line"}},'v-skeleton-loader',_vm.attrs,false))]},proxy:true}],null,false,745838220)}):_c('v-data-table',{attrs:{"headers":_vm.dataheaders,"items":_vm.data,"search":_vm.search,"loading":_vm.loadingData,"sort-by":"co_cli","footer-props":{itemsPerPageText: 'Mostrar'}},on:{"update:sort-by":_vm.getSort},scopedSlots:_vm._u([{key:"item.cli_des",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mt-n1",attrs:{"small":"","color":"primary","left":""}},[_vm._v(" mdi-account-tie ")]),_c('span',{staticClass:"primary--text font-weight-bold",domProps:{"textContent":_vm._s(item.cli_des)}}),_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"subtitle-2"},[_vm._v("RIF: "+_vm._s(item.rif))]),_c('v-chip',{staticClass:"ma-2 font-weight-bold",attrs:{"color":parseInt(item.inactivo) === 0 ? 'success' : parseInt(item.inactivo) === 1 ? 'disabled-alert' : 'transparent',"x-small":"","label":"","pill":"","dark":""}},[_vm._v(" "+_vm._s(parseInt(item.inactivo) === 0 ? 'Activo' : parseInt(item.inactivo) === 1 ? 'Inactivo' : '')+" ")])],1)]}},{key:"item.direc1",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"},domProps:{"textContent":_vm._s(item.direc1)}})]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-chip',{staticClass:"pa-1",attrs:{"label":"","color":"blue-grey lighten-5"}},[_c('v-icon',{staticClass:"mx-2",attrs:{"size":"20","color":item.lat !== null && item.lng !== null ? 'icono' : 'blue-grey lighten-3'},domProps:{"textContent":_vm._s(item.lat !== null && item.lng !== null ? 'mdi-map-marker-check' : 'mdi-map-marker-off' )}}),_c('v-icon',{staticClass:"mx-2",attrs:{"size":"20","color":item.visita_programadas ? 'icono' : 'blue-grey lighten-3'},domProps:{"textContent":_vm._s(item.visita_programadas ? 'mdi-alarm-check' : 'mdi-alarm-off' )}}),_vm._t("actionInfo",null,{"client":item})],2)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions--style text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 pa-2",attrs:{"depressed":"","min-width":"23px","height":"31px","to":{name: 'Cliente', params: { cod: item.co_cli.trim() }}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-text-box-check-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Detalles")])])],1)]}},{key:"loading",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"table-tbody"}},'v-skeleton-loader',_vm.attrs,false))],1)],1)]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }