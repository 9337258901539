<template>
<div>
  <v-row class="pa-0 ma-0">
    <v-col cols="12" class="pa-0">
       <v-data-iterator
        :items="data"
        item-key="name"
        :items-per-page="10"
        :search="search"
        :loading="loadingData"
        v-if="$vuetify.breakpoint.xsOnly"
      >
        <template v-slot:default="{ items }">
          <template v-for="(client,i) in items">
            <mobile-clients :cliente="client" :key="'mobilec-'+i">
              <template #action>
                <v-btn
                  depressed
                  color="secondary"
                  outlined
                  class="mx-2 pa-2"
                  min-width="23px"
                  height="31px"
                  :to="{name: 'Cliente', params: { cod: client.co_cli.trim() }}"
                >
                <v-icon size="20">mdi-text-box-check-outline</v-icon>

                </v-btn>
              </template>
            </mobile-clients>
          </template>
        </template>
        <template #loading>
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-two-line, list-item, list-item-two-line"
          ></v-skeleton-loader>
        </template>
      </v-data-iterator>
      <v-data-table
        :headers="dataheaders"
        :items="data"
        :search="search"
        :loading="loadingData"
        sort-by="co_cli"
        :footer-props="{itemsPerPageText: 'Mostrar'}"
        @update:sort-by="getSort"
        v-else
      >
        <template v-slot:item.cli_des="{ item }">
          <v-icon
            small
            color="primary"
            left
            class="mt-n1"
          >
            mdi-account-tie
          </v-icon>
          <span class="primary--text font-weight-bold" v-text="item.cli_des" />
          <div class="d-flex flex-row align-center">
            <div class="subtitle-2">RIF: {{item.rif}}</div>
            <v-chip
              class="ma-2 font-weight-bold"
              :color="parseInt(item.inactivo) === 0 ? 'success' : parseInt(item.inactivo) === 1 ? 'disabled-alert' : 'transparent' "
              x-small
              label
              pill
              dark
            >
              {{ parseInt(item.inactivo) === 0 ? 'Activo' : parseInt(item.inactivo) === 1 ? 'Inactivo' : '' }}
            </v-chip>
          </div>
        </template>
        <template v-slot:item.direc1="{ item }">
          <span
            class="d-inline-block text-truncate"
            style="max-width: 250px;"
            v-text="item.direc1"
          />
        </template>
        <template v-slot:item.info="{ item }">
          <div class="d-flex justify-end">
            <v-chip
              label
              color="blue-grey lighten-5"
              class="pa-1"
            >

              <v-icon
                size="20"
                :color="item.lat !== null && item.lng !== null ? 'icono' : 'blue-grey lighten-3'"
                class="mx-2"
                v-text="item.lat !== null && item.lng !== null ? 'mdi-map-marker-check' : 'mdi-map-marker-off' "
              ></v-icon>

              <v-icon
                size="20"
                :color="item.visita_programadas ? 'icono' : 'blue-grey lighten-3'"
                class="mx-2"
                v-text="item.visita_programadas ? 'mdi-alarm-check' : 'mdi-alarm-off' "
              ></v-icon>
              <!-- <v-btn plain small color="icono" class="pr-1">
                <v-icon small color="blue-grey">mdi-menu-down</v-icon>
              </v-btn> -->
              <slot name="actionInfo" :client="item"></slot>

            </v-chip>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="actions--style text-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  class="mx-2 pa-2"
                  min-width="23px"
                  height="31px"
                  v-bind="attrs"
                  v-on="on"
                  :to="{name: 'Cliente', params: { cod: item.co_cli.trim() }}"
                >
                <v-icon size="20">mdi-text-box-check-outline</v-icon>
                </v-btn>
              </template>
              <span>Ver Detalles</span>
            </v-tooltip>
          </div>
        </template>
        <template #loading>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                v-bind="attrs"
                type="table-tbody"
              ></v-skeleton-loader>
            </v-col>
          </v-row>

        </template>
      </v-data-table>
    </v-col>
  </v-row>
</div>
</template>
<script>
export default {
  name: 'DataAccounts',
  components:{
    MobileClients: () => import(
      /* webpackChunkName: "modal-account" */
      './MobileClients.vue'
    ),
  },
  props:{
    headers: {
      type: Array,
      default: () => ([]),
    },
    data: {
      type: Array,
      default: () => ([]),
    },
    search: String,
    loadingData: Boolean,
  },
  data: () => ({
    attrs: {
      boilerplate: false,
      loading: true,
    },
    edit: false,
    client: {},

  }),
  methods:{
    gestionItem(item){
      this.client = item;
      this.edit = true;
    },
    getSort(value){
      console.log(value)
    }
  },
  computed:{
    dataheaders(){
      return this.headers.filter(item => item.active)
    }
  }
}
</script>
