<template>
  <v-col cols="12" md="7" lg="7" class="pa-4">
    <v-card outlined tile class="mb-3">
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="secondary" class="blue-grey lighten-5" dark>
                mdi-account
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-row no-gutters>
                <v-col cols="7">
                  <v-list-item-subtitle
                    class="caption blue-grey--text text--darken-3">RESPONSABLE</v-list-item-subtitle>
                  <v-list-item-title class="font-weight-bold primary--text" v-text="info.respons"></v-list-item-title>
                </v-col>
                <v-col cols="5">
                  <v-list-item-subtitle class="caption blue-grey--text text--darken-3">Vendedor: </v-list-item-subtitle>
                  <v-list-item-title class="font-weight-bold primary--text" v-text="info.ven_des"></v-list-item-title>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="secondary" class="blue-grey lighten-5">
                mdi-map-marker
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle class="caption blue-grey--text text--darken-3">DIRECCIÓN</v-list-item-subtitle>
              <v-list-item-title class="font-weight-bold primary--text space-normal"
                v-text="info.direc1"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card outlined tile>
      <v-card-text>
        <v-row class="px-4">
          <v-col cols="4">
            <span class="caption text-uppercase d-block blue-grey--text text--darken-3">
              Código
              <v-icon small color="blue-grey" class="mt-n1">mdi-barcode-scan</v-icon>
            </span>
            <span class="font-weight-bold primary--text" v-text="info.co_cli" />
          </v-col>
          <v-col cols="4">
            <span class="caption text-uppercase d-block blue-grey--text text--darken-3">
              Sucursal
            </span>
            <span class="font-weight-bold primary--text" v-text="info.co_sucu" />
          </v-col>
          <v-col cols="4">
            <span class="caption text-uppercase d-block blue-grey--text text--darken-3">
              Zona
            </span>
            <span class="font-weight-bold primary--text" v-text="info.co_zon" />
          </v-col>
        </v-row>
        <v-row class="px-4 mb-4">
          <v-col cols="6">
            <v-icon small left color="blue-grey">mdi-email-check-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">RIF</span>
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold primary--text" v-text="info.rif" />
          </v-col>
          <v-col cols="12" class="py-1 my-0">
            <v-divider />
          </v-col>
          <v-col cols="6">
            <v-icon small left color="blue-grey">mdi-email-check-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Email</span>
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold primary--text" v-text="info.email" />
          </v-col>
          <v-col cols="12" class="py-1 my-0">
            <v-divider />
          </v-col>
          <v-col cols="6">
            <v-icon small left color="blue-grey">mdi-cellphone-iphone</v-icon>
            <span class="caption blue-grey--text text--darken-3">Teléfono</span>
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold primary--text" v-text="info.telefonos" />
          </v-col>
          <v-col cols="12" class="py-1 my-0">
            <v-divider />
          </v-col>
          <v-col cols="6">
            <v-icon small left color="blue-grey">mdi-city-variant-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Estado</span>
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold primary--text" v-text="info.zip" />
          </v-col>
          <v-col cols="12" class="py-1 my-0">
            <v-divider />
          </v-col>
          <v-col cols="6">
            <v-icon small left color="blue-grey">mdi-city-variant-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Ciudad</span>
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold primary--text" v-text="info.ciudad" />
          </v-col>
          <v-col cols="12" class="py-1 my-0">
            <v-divider />
          </v-col>
          <v-col cols="6">
            <v-icon small left color="blue-grey">mdi-map-marker-outline</v-icon>
            <span class="caption blue-grey--text text--darken-3">Latitud/Longitud actual</span>
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold primary--text" v-if="info.lat">LAT {{ info.lat }} / LNG {{ info.lng }}</span>
            <span class="font-weight-bold red--text" v-else>S/A</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div>
      <!-- <v-btn color="primary" class="ml-2 mt-10" outlined small
        :to="{ name: 'Agregar Vendedores a Clientes', params: { co_cli: info.co_cli, portafolio: portafolio } }">
        <v-icon size="22" left>mdi-account-plus</v-icon>
        Agregar vendedor
      </v-btn> -->

      <v-btn color="primary" class="ml-2 mt-10" outlined small @click="actionEdit({'newSeller':'true'})" >
        <v-icon size="22" left>mdi-account-plus</v-icon>
        Agregar vendedor
      </v-btn>
    </div>

    <div class="ma-3 text-h4" align="center">
      El cliente <label class="font-weight-black"style="color:orange">{{ portafolio === true ? 'si' : 'no' }}</label> usa portafolio dividido
    </div>
    <v-card outlined tile class="my-3" v-for="(item, index) in visita">
      <v-card-title class="d-flex justify-space-between pb-0">
        <div>
          <v-icon size="20" left color="secondary">
            mdi-alarm-check
          </v-icon>
          <span class="body-2 font-weight-medium"> Horario de Atención </span>
        </div>

        <v-btn v-if="portafolio == true" text color="primary" @click="actionDelete(item)">
          <v-icon left color="red">mdi-close-circle</v-icon>
          Eliminar
        </v-btn>

        <v-btn v-if="$hasPermission('clientes.frecuencia.editar')" text color="primary" @click="actionEdit(item)">
          <v-icon left>mdi-pencil-outline</v-icon>
          Actualizar
        </v-btn>
      </v-card-title>
      <v-card-text v-if="item.id">
        <v-col cols="12" class="d-flex flex-row align-center justify-center">
          <v-row>
            <v-col cols="6">
              <v-icon small left color="blue-grey">mdi-account</v-icon>
              <span class="caption blue-grey--text text--darken-3">Vendedor</span>
            </v-col>

            <v-col cols="6">
              <span class="font-weight-bold primary--text text-capitalize">
                {{ item.ven_des }}
              </span>
            </v-col>
            <v-col cols="12" class="py-1 my-0">
              <v-divider />
            </v-col>

            <v-col cols="6">
              <v-icon small left color="blue-grey">mdi-calendar-month</v-icon>
              <span class="caption blue-grey--text text--darken-3">Fecha Inicio</span>
            </v-col>

            <v-col cols="6">
              <span class="font-weight-bold primary--text text-capitalize">
                {{ item.fecha_inicio | largeDate }}
              </span>
            </v-col>
            <v-col cols="12" class="py-1 my-0">
              <v-divider />
            </v-col>

            <v-col cols="6">
              <v-icon small left color="blue-grey">mdi-replay</v-icon>
              <span class="caption blue-grey--text text--darken-3">Frecuencia</span>
            </v-col>

            <v-col cols="6">
              <span class="font-weight-bold primary--text text-capitalize" v-text="item.frecuencia" />
            </v-col>

            <v-col cols="12" class="py-1 my-0">
              <v-divider />
            </v-col>

            <v-col cols="6">
              <v-icon small left color="blue-grey">mdi-calendar-today</v-icon>
              <span class="caption blue-grey--text text--darken-3">Días de Semana 1</span>
            </v-col>

            <v-col cols="6">
              <v-row no-gutters>
                <span class="font-weight-bold primary--text mr-2 col col-5" v-if="item.lunes == 1">
                  <v-icon color="icono" small>mdi-checkbox-marked-circle</v-icon> Lunes
                </span>
                <span class="font-weight-bold primary--text mr-2 col col-5" v-if="item.martes == 1">
                  <v-icon color="red" small>mdi-checkbox-marked-circle</v-icon> Martes
                </span>
                <span class="font-weight-bold primary--text mr-2 col col-5" v-if="item.miercoles == 1">
                  <v-icon color="info" small>mdi-checkbox-marked-circle</v-icon> Miércoles
                </span>
                <span class="font-weight-bold primary--text mr-2 col col-5" v-if="item.jueves == 1">
                  <v-icon color="orange" small>mdi-checkbox-marked-circle</v-icon> Jueves
                </span>
                <span class="font-weight-bold primary--text mr-2 col col-5" v-if="item.viernes == 1">
                  <v-icon color="blue" small>mdi-checkbox-marked-circle</v-icon> Viernes
                </span>
                <span class="font-weight-bold primary--text mr-2 col col-5" v-if="item.sabado == 1">
                  <v-icon color="warning" small>mdi-checkbox-marked-circle</v-icon> Sábado
                </span>
                <span class="font-weight-bold primary--text mr-2 col col-5" v-if="item.domingo == 1">
                  <v-icon color="success" small>mdi-checkbox-marked-circle</v-icon> Domingo
                </span>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-text v-else style="background-color: #D3EEFF; color:#000; padding-top: 20px;" class="h5">
       <v-icon size="20" left color="secondary">
        mdi-alert-outline
          </v-icon>
          Este Cliente no tiene asignado un Horario de Visitas. Oprima el botón "Actualizar" para gestionar el horario.
      </v-card-text>
      <!--
      <v-card-text v-else-if="visita[0].fecuencia !== ''">
        <v-row>
          <v-col cols="6">
            <v-icon small left color="blue-grey">mdi-calendar-month</v-icon>
            <span class="caption blue-grey--text text--darken-3">Fecha Inicio</span>
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold primary--text text-capitalize">
              {{ visita.fecha_inicio | largeDate }}
            </span>
          </v-col>
          <v-col cols="12" class="py-1 my-0">
            <v-divider />
          </v-col>
          <v-col cols="6">
            <v-icon small left color="blue-grey">mdi-replay</v-icon>
            <span class="caption blue-grey--text text--darken-3">Frecuencia</span>
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold primary--text text-capitalize" v-text="visita.frecuencia" />
          </v-col>
          <v-col cols="12" class="py-1 my-0">
            <v-divider />
          </v-col>
          <v-col cols="6">
            <v-icon small left color="blue-grey">mdi-calendar-today</v-icon>
            <span class="caption blue-grey--text text--darken-3">Días de Semana</span>
          </v-col>
          <v-col cols="6">
            <v-row no-gutters>
              <span class="font-weight-bold primary--text mr-2 col col-5" v-if="visita.lunes == 1">
                <v-icon color="icono" small>mdi-checkbox-marked-circle</v-icon> Lunes
              </span>
              <span class="font-weight-bold primary--text mr-2 col col-5" v-if="visita.martes == 1">
                <v-icon color="red" small>mdi-checkbox-marked-circle</v-icon> Martes
              </span>
              <span class="font-weight-bold primary--text mr-2 col col-5" v-if="visita.miercoles == 1">
                <v-icon color="info" small>mdi-checkbox-marked-circle</v-icon> Miércoles
              </span>
              <span class="font-weight-bold primary--text mr-2 col col-5" v-if="visita.jueves == 1">
                <v-icon color="orange" small>mdi-checkbox-marked-circle</v-icon> Jueves
              </span>
              <span class="font-weight-bold primary--text mr-2 col col-5" v-if="visita.viernes == 1">
                <v-icon color="blue" small>mdi-checkbox-marked-circle</v-icon> Viernes
              </span>
              <span class="font-weight-bold primary--text mr-2 col col-5" v-if="visita.sabado == 1">
                <v-icon color="warning" small>mdi-checkbox-marked-circle</v-icon> Sábado
              </span>
              <span class="font-weight-bold primary--text mr-2 col col-5" v-if="visita.domingo == 1">
                <v-icon color="success" small>mdi-checkbox-marked-circle</v-icon> Domingo
              </span>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      -->
    </v-card>
    <template v-if="$hasPermission('clientes.frecuencia.editar')">
      <modal-horario v-model="toggle_edit_day" :dataVisita="schedules" />
    </template>

    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-title class="text-h4 lighten-2">
              <v-icon color="warning" large class="pr-3">mdi-information</v-icon>
              Validación
            </v-card-title>

            <v-card-text class="px-8 py-6">
              ¿Está seguro que desea desvincular el vendedor {{ sellerSelected.ven_des }} del cliente {{ info.cli_des
              }}?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                Cancelar
              </v-btn>

              <v-btn color="primary" text @click="deleteConfirm()">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </v-col>
</template>
<script>
import { get } from 'vuex-pathify'
export default {
  name: 'TabDetails',
  components: {
    ModalHorario: () => import(
      /* webpackChunkName: "modal-horario" */
      './ModalHorario.vue'
    ),
  },
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    visita: {
      type: Array,
      default: () => ([])
    },
    portafolio: {
      type: Boolean,
      default: () => (false)
    }
  },
  data: () => ({
    toggle_edit_day: false,
    schedules: {},
    sellerSelected: {},
    dialog: false,
  }),
  methods: {
    actionEdit(obj) {
      //console.log(this.visita);
      //  console.log(this.info);

      if (obj.newSeller) {
        //   console.log("lleno", this.portafolio);
        obj.newSeller = true;
      } else {
        //   console.log("vacio", this.portafolio);
        obj.newSeller = false;
      }
      //return;

      // if (this.portafolio === true) {
         obj.portafolio = this.portafolio;
      // };

      this.schedules = obj;
      this.toggle_edit_day = true;
      this.$vuetify.goTo(9999);
    },
    async addSeller() {
      const { data } = await this.$api.get('users');
      console.log(data.data);
    },
    actionDelete(obj) {
      this.sellerSelected = obj;
      this.dialog = true;
      this.$vuetify.goTo(9999);
    },
    async deleteConfirm() {
      this.$api.delete(`clientes/${this.sellerSelected.id}/v2/visitas`);

      this.$emit('updateSellers');
      this.dialog = false;
    },
  }
}
</script>
<style lang="sass">

.space-normal
  white-space: normal !important
  overflow: visible !important
</style>
